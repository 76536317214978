import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { isPieceCircleShape } from '../annot/piece/circle/shape'
import { PIECE_CIRCLE_TOOL_ID } from '../annot/piece/circle/tool'
import { FireAreaHelp } from '../help/actor/fire/area/help'
import { FireSampleHeadHelp } from '../help/actor/fire/head/sample-help'
import { FirePipeHelp } from '../help/actor/fire/pipe/help'
import { FireSizeHelp } from '../help/actor/fire/size/help'
import { FireWaterSourceHelp } from '../help/actor/fire/water-source/help'
import { useSetting } from '../setting/setting'
import { PredictAreaHelp } from './area-help'
import { isPredictAreaShape } from './area/shape'
import { PredictAreaTool } from './area/tool'
import { isPredictGroupSampleShape } from './group-sample/shape'
import { PredictGroupSampleTool } from './group-sample/tool'

export const PredictHelp = track((): ReactElement | null => {
  const editor = useEditor()
  const toolId = editor.getCurrentToolId()

  const { setting } = useSetting()
  const model = setting.predictModel

  const predictArea = editor.getCurrentToolId() === PredictAreaTool.id
    || editor.getCurrentPageShapes().some(isPredictAreaShape)

  const predictGroupSample = editor.getCurrentToolId() === PredictGroupSampleTool.id
    || editor.getCurrentPageShapes().some(isPredictGroupSampleShape)

  const waterSource = editor.getCurrentToolId() === PIECE_CIRCLE_TOOL_ID
    || editor.getCurrentPageShapes().some(isPieceCircleShape)

  if (!predictArea && !predictGroupSample && !waterSource)
    return null

  switch (model) {
    case 'fire-head':
    case 'fire-alarm':
      return toolId === PredictGroupSampleTool.id
        ? <FireSampleHeadHelp />
        : <FireAreaHelp />
    case 'fire-pipe':
      return toolId === PIECE_CIRCLE_TOOL_ID
        ? <FireWaterSourceHelp model={model} />
        : <FirePipeHelp />
    case 'fire-pipe-diameter':
      return toolId === PIECE_CIRCLE_TOOL_ID
        ? <FireWaterSourceHelp model={model} />
        : <FireSizeHelp />
    default:
      return <PredictAreaHelp />
  }
})
