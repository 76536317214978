import type { TLGeoShape, TLShape } from 'tldraw'
import type { SystemType } from '../../../attr/field/equip/value'
import type { EditorShapePartial } from '../../../editor/shape/base'
import type { PieceMetaBase } from '../shape'
import { isPieceShapeBase } from '../shape'

type Meta = PieceMetaBase & {
  shape: 'box'
  system: SystemType
}

export type PieceBoxShape = TLGeoShape & { meta: Meta }

export type PieceBoxShapePartial = EditorShapePartial<TLGeoShape, Meta>

export function isPieceBoxShape(
  shape: TLShape,
): shape is PieceBoxShape {
  if (!isPieceShapeBase(shape))
    return false
  const test = shape as PieceBoxShape
  return test.meta.shape === 'box'
}

export function isFirePieceShape(
  shape: TLShape,
): shape is PieceBoxShape {
  return isPieceBoxShape(shape) && shape.meta.system === 'fire'
}

export function isElectricalPieceShape(
  shape: TLShape,
): shape is PieceBoxShape {
  return isPieceBoxShape(shape) && shape.meta.system === 'electrical'
}
