import { Field as FluentField, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactNode } from 'react'

const useStyles = makeStyles({
  label: {
    display: 'flex',
    alignItems: 'center',
    ...(shorthands.gap(tokens.spacingVerticalXS)),
  },
  icon: {
    color: tokens.colorBrandForeground1,
  },
  control: {
    'minWidth': 'auto',
    '> .fui-Dropdown__clearButton': {
      marginRight: tokens.spacingHorizontalXS,
    },
    'minHeight': '24px',
  },
})

type Icon = (props: {
  className: string
}) => ReactNode

type ChildrenRender = (props: {
  className: string
  // This value is intentionally fixed to make it easier to use with different
  // types of controls (e.g. dropdown, input, button, etc.)
  appearance: 'filled-darker'
}) => ReactNode

type Children = ReactNode | ChildrenRender

/**
 * Wrapper of Fluent's Field that provides convenient utilities such as label
 * icon and default control appearance.
 */
export function Field(props: {
  label: string
  children: Children
  icon?: Icon
  validation?: string
  className?: string
}): JSX.Element {
  const { children, icon, label, validation, className } = props

  const s = useStyles()

  return (
    <FluentField
      label={(
        <div className={s.label}>
          {icon
            ? icon({ className: s.icon })
            : null}
          <span>{label}</span>
        </div>
      )}
      validationState={validation ? 'error' : 'none'}
      validationMessage={validation}
      size="small"
      className={className}
    >
      {typeof children === 'function'
        ? children({
          className: s.control,
          appearance: 'filled-darker',
        })
        : children}
    </FluentField>
  )
}
