import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
// To support Safari/iPad
// https://github.com/mozilla/pdf.js/wiki/Frequently-Asked-Questions#which-browsersenvironments-are-supported
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf.mjs'
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker?url'
import { server } from '../../util/data/server'
import { getStrict } from '../../util/web/primitive'
import { PAGE_ID } from '../state/id'

GlobalWorkerOptions.workerSrc = pdfjsWorker

export type PagePdf = {
  // Maybe it's better to use off-screen canvas?
  // But "transfer to image bitmap" would clear the off-screen canvas every time,
  // while we want to copy the canvas content to other places later.
  canvas: HTMLCanvasElement
  width: number
  height: number
  images: PageImage[]
}

export type PageImage = {
  id: string
  sources: string[]
  shape: {
    y: number
    x: number
    w: number
    h: number
  }
}

/**
 * PDF is rendered on a canvas, which most browsers limit to 16k pixels in either dimension.
 * Most PDFs have the original size of around 1k to 2k pixels, so 8x is a safe scale.
 * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
 *
 * Recently we have PDFs that are more complicated, so 8x doesn't work any more.
 * It's not that the browser could not handle it, but it forces the browsers to use GPU,
 * which surprisingly is much slower than software rendering.
 * Temporary solution is to lower the resolution for now.
 *
 * @todo Splitting render resolution 4x and AI processing 8x instead.
 * @see https://h2corporation.slack.com/archives/C03TF9K9E3G/p1732577670647659?thread_ts=1732254816.159319&cid=C03TF9K9E3G
 */
export const PAGE_PDF_SCALE = 8

export const PAGE_PDF_SCALES = [
  { zoomLevel: 0, scale: 4 },
  { zoomLevel: 1, scale: 4 },
  { zoomLevel: 2, scale: 6 },
  { zoomLevel: 4, scale: PAGE_PDF_SCALE },
]

export const PAGE_PDF_N_TILES = 8

/**
 * It's intentional that this is a different query than the "page detail" query,
 * even though they use the same endpoint.
 * This is because "get page detail" returns a new "signed url" on each request,
 * which causes caching issues easily.
 * For example, if the queries are the same,
 * then updating the page's scale would unexpectedly cause the PDF to reload.
 */
export function usePagePdf(): UseQueryResult<
  PagePdf,
  // Important: The query fn here does not only get the URL,
  // but also fetches and reads the PDF, so error could be more than API error
  Error
> {
  return useQuery({
    queryKey: ['pdf'],
    queryFn: async (): Promise<PagePdf> => {
      const { signedURL: url } = await server.getPageDetail(PAGE_ID)
      const pdf = await getDocument({ url, cMapUrl: 'public/cmaps', cMapPacked: true }).promise

      // Our PDF files have only 1 page by design
      const page = await pdf.getPage(1)

      const viewports = PAGE_PDF_SCALES.map(({ scale }) => page.getViewport({ scale }))

      const canvases = PAGE_PDF_SCALES.map((_, idx) => {
        const canvas = document.createElement('canvas')
        canvas.width = viewports[idx].width
        canvas.height = viewports[idx].height
        return canvas
      })

      await Promise.all(canvases.map((canvas, idx) => {
        const viewport = viewports[idx]
        const canvasContext = getStrict(canvas.getContext('2d'))
        return page.render({ canvasContext, viewport }).promise
      }))

      const tmpCanvases = PAGE_PDF_SCALES.map((_, idx) => {
        const canvas = document.createElement('canvas')
        canvas.width = viewports[idx].width / PAGE_PDF_N_TILES
        canvas.height = viewports[idx].height / PAGE_PDF_N_TILES
        return canvas
      })

      const images = []

      const mainViewport = viewports[viewports.length - 1]
      const mainCanvas = canvases[canvases.length - 1]

      const pdfWidth = mainViewport.width / mainViewport.scale
      const pdfHeight = mainViewport.height / mainViewport.scale

      for (let row = 0; row < PAGE_PDF_N_TILES; row++) {
        for (let col = 0; col < PAGE_PDF_N_TILES; col++) {
          const image: PageImage = {
            id: crypto.randomUUID(),
            sources: [],
            shape: {
              x: col * (pdfWidth / PAGE_PDF_N_TILES),
              y: row * (pdfHeight / PAGE_PDF_N_TILES),
              w: pdfWidth / PAGE_PDF_N_TILES,
              h: pdfHeight / PAGE_PDF_N_TILES,
            },
          }

          for (let idx = 0; idx < PAGE_PDF_SCALES.length; idx++) {
            const tileWidth = viewports[idx].width / PAGE_PDF_N_TILES
            const tileHeight = viewports[idx].height / PAGE_PDF_N_TILES

            const sx = col * tileWidth
            const sy = row * tileHeight

            const canvas = tmpCanvases[idx]
            const context = getStrict(canvas.getContext('2d'))

            context.clearRect(0, 0, tileWidth, tileHeight)
            context.drawImage(canvases[idx], sx, sy, tileWidth, tileHeight, 0, 0, tileWidth, tileHeight)

            image.sources.push(canvas.toDataURL())
          }

          images.push(image)
        }
      }

      // Clean up
      page.cleanup()
      await pdf.cleanup()
      await pdf.destroy()

      return {
        canvas: mainCanvas,
        images,
        width: mainViewport.width / PAGE_PDF_SCALE,
        height: mainViewport.height / PAGE_PDF_SCALE,
      }
    },
  },
  )
}
