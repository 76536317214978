import { Dropdown, Option } from '@fluentui/react-components'
import { Box16Filled } from '@fluentui/react-icons'
import { Field } from '../../../ui/field'
import type { AttrSelection } from '../../../util/attr/selection'
import { getAttrSelectionValueArray, getAttrSelectionValueWhen } from '../../../util/attr/selection'
import { t } from '../../../util/intl/t'
import type { AttrCModuleValue } from './value'
import { useAttrCModuleOptions } from './value'

export function AttrCModuleField(props: {
  cModule: AttrSelection<AttrCModuleValue>
  setCModule: (cModule: AttrCModuleValue) => void
}): JSX.Element | null {
  const { cModule: selection, setCModule: setValue } = props

  const options = useAttrCModuleOptions()
  if (options.length === 0)
    return null

  return (
    <Field
      icon={icon => <Box16Filled className={icon.className} />}
      label={t('attr.field.c-module')}
    >
      {control => (
        <Dropdown
          value={getAttrSelectionValueWhen(selection, {
            mixed: t('attr.c-module.mixed'),
            same: value => options
              .find(m => m.id === value)
              ?.name ?? '',
          })}
          selectedOptions={getAttrSelectionValueArray(selection)}
          onOptionSelect={(_event, data) => {
            setValue(data.optionValue ?? '')
          }}
          className={control.className}
          appearance={control.appearance}
        >
          {options.map(option => (
            <Option
              key={`${option.id}`}
              value={option.id}
            >
              {`${option.name} (×${option.quantity})`}
            </Option>
          ))}
        </Dropdown>
      )}
    </Field>
  )
}
