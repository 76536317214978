import { BotSparkle20Filled, BotSparkle20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { SelectTool, useEditor } from 'tldraw'
import { usePage } from '../../page/state/context'
import { useSetting } from '../../setting/setting'
import { MenuRadio } from '../../ui/menu'
import { t } from '../../util/intl/t'
import { groupByPredictSystem } from '../system/group'
import { PredictModelElectrical } from './electrical'
import type { PredictModelValue } from './option'
import { PREDICT_MODELS, predictModelValueSchema } from './option'

const OPTIONS = groupByPredictSystem(PREDICT_MODELS)

export const ELECTRICAL_ORG_PROD = '44bf1cc2-85a3-46e7-99bd-3d705e0cc174'
export const ELECTRICAL_ORG_QA = 'd23db3a0-3732-4586-a254-a1af6316450f'

function getModelList(orgID: string) {
  if (orgID === ELECTRICAL_ORG_PROD || orgID === ELECTRICAL_ORG_QA)
    return groupByPredictSystem([PredictModelElectrical])
  return OPTIONS
}

export function PredictModelSelect(props: {
  highlight: boolean
}): ReactElement {
  const { highlight } = props
  const editor = useEditor()
  const { setting, updateSetting } = useSetting()

  const page = usePage()
  const orgID = page.page.orgID
  const models = getModelList(orgID)

  return (
    <MenuRadio<PredictModelValue>
      value={setting.predictModel}
      setValue={(model) => {
        updateSetting({ predictModel: model })
        editor.setCurrentTool(SelectTool.id)
      }}
      displayFallback={t('predict.model.action')}
      //
      groups={models.map(group => ({
        label: group.system.label,
        options: group.models.map(model => ({
          label: model.label,
          value: model.value,
        })),
      }))}
      parse={predictModelValueSchema.parse}
      button={{
        appearance: highlight ? 'primary' : 'subtle',
        icon: highlight ? <BotSparkle20Filled /> : <BotSparkle20Regular />,
      }}
    />
  )
}
