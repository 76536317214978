import { Divider } from '@fluentui/react-components'
import { CalendarPattern16Filled } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { AnnotShape } from '../annot/shape/shape'
import { ATTR_EQUIP_VALUES } from '../attr/field/equip/value'
import { AttrThicknessField } from '../attr/field/thickness/field'
import type { AttrSelection, AttrSelectionValue } from '../util/attr/selection'
import { createAttrSelection, getAttrSelectionValue } from '../util/attr/selection'
import { useAttrTreeDropdownValue } from '../util/attr/tree/dropdown'
import { AttrTreeField } from '../util/attr/tree/field'
import { t } from '../util/intl/t'
import { useInsulAttrs } from './context'
import { InsulPanelHeader } from './header'
import type { InsulAttrValue } from './value'
import { INSUL_ATTR_DEFAULT } from './value'

export function InsulAttrPanel(props: {
  /**
   * We could use the editor ourselves here, but it's better to ask the shapes
   * from outside to emphasise that the main attribute panel should control this
   * insulation attribute panel.
   */
  shapes: AnnotShape[]
  set: (update: Partial<InsulAttrValue>) => void
}): ReactElement {
  const { shapes, set } = props

  const all = useInsulAttrs()

  const attrs = shapes
    .map(shape => all.attrs[shape.meta.group] ?? INSUL_ATTR_DEFAULT)

  const create = <Value extends AttrSelectionValue>(
    getter: (attr: InsulAttrValue) => Value,
  ): AttrSelection<Value> => {
    return createAttrSelection(attrs.map(getter))
  }

  const get = getAttrSelectionValue

  const enabled = create(a => a.enabled)
  const material1 = create(a => a.material1)
  const material2 = create(a => a.material2)
  const thickness = create(a => a.thickness)

  const material1Value = useAttrTreeDropdownValue({
    mixedText: t('insul.material-1.mixed'),
    tree: {
      equip: ATTR_EQUIP_VALUES.INSULATION,
      type: 'material',
      node: 0,
    },
    value: material1,
  })
  const material2Value = useAttrTreeDropdownValue({
    mixedText: t('insul.material-2.mixed'),
    tree: {
      equip: ATTR_EQUIP_VALUES.INSULATION,
      type: 'material',
      node: Number.parseInt(get(material1, '-1')),
    },
    value: material2,
  })

  const fields = (
    <>
      <div><Divider /></div>
      {material1Value && (
        <AttrTreeField
          name="material1"
          value={material1Value}
          setValue={set}
          resetChildValue={['material2', 'thickness']}
          icon={icon => <CalendarPattern16Filled className={icon.className} />}
          label={t('insul.field.material-1')}
        />
      )}
      {material2Value && (
        <AttrTreeField
          name="material2"
          value={material2Value}
          setValue={set}
          resetChildValue={['thickness']}
          icon={icon => <CalendarPattern16Filled className={icon.className} />}
          label={t('insul.field.material-2')}
        />
      )}
      <AttrThicknessField
        value={thickness}
        setValue={thickness => set({ thickness })}
      />
    </>
  )

  return (
    <>
      <InsulPanelHeader
        enabled={enabled}
        setEnabled={enabled => set({ enabled })}
      />
      {get(enabled, false) ? fields : null}
    </>
  )
}
