import { Combobox, Option } from '@fluentui/react-components'
import { Ruler16Filled } from '@fluentui/react-icons'
import { Field } from '../../../ui/field'
import type { AttrSelection } from '../../../util/attr/selection'
import { getAttrSelectionValue, getAttrSelectionValueArrayAllowEmpty } from '../../../util/attr/selection'
import { t } from '../../../util/intl/t'
import { ATTR_THICKNESS_OPTIONS, type AttrThicknessValue } from './value'

type Props = {
  value: AttrSelection<AttrThicknessValue>
  setValue: (value: AttrThicknessValue) => void
}

export function AttrThicknessField(props: Props): JSX.Element {
  const { value, setValue } = props

  return (
    <Field
      icon={icon => <Ruler16Filled className={icon.className} />}
      label={`${t('attr.field.thickness')}`}
    >
      {control => (
        <Combobox
          value={getAttrSelectionValue(value, t('attr.insul-thickness.mixed'))}
          selectedOptions={getAttrSelectionValueArrayAllowEmpty(value)}
          onOptionSelect={(_event, data) => {
            if (data.optionValue)
              setValue(data.optionValue)
          }}
          onInput={(event) => {
            setValue(event.currentTarget.value)
          }}
          className={control.className}
          appearance={control.appearance}
        >
          {ATTR_THICKNESS_OPTIONS.map(thickness => (
            <Option key={thickness.value} value={thickness.value}>
              {thickness.label}
            </Option>
          ))}
        </Combobox>
      )}
    </Field>
  )
}
