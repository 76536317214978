import { Button, Input, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Dismiss16Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { Field } from '../../ui/field'
import { t } from '../../util/intl/t'
import type { AIParams, AIParamsFormFormula } from './type'

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalM,
    alignItems: 'start',
  },
  formula: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
    width: '100%',
  },
  alignStart: {
    alignItems: 'start',
  },
  alignEnd: {
    alignItems: 'end',
  },
  alignCenter: {
    alignItems: 'center',
  },
  formulas: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalM,
  },
  field: {
    width: '64px',
  },
  input: {
    width: '64px',
  },
  gapS: {
    rowGap: tokens.spacingHorizontalS,
  },
  select: {
    '> select': {
      minWidth: 'auto',
      paddingRight: 0,
    },
  },
  title: {
    margin: `${tokens.spacingHorizontalS} 0`,
  },
  marginBottom: {
    marginBottom: `${tokens.spacingHorizontalM}`,
  },
})

export function PredictParamFormulaForm(props: {
  formulas: AIParamsFormFormula[]
  onChange: (formulas: AIParamsFormFormula[]) => void
  form: UseFormReturn<AIParams>
  indexRule: number
}): ReactElement {
  const { formulas, onChange, form, indexRule } = props

  const { errors } = form.formState

  const s = useStyles()

  const updateFormulas = (index: number, formula: AIParamsFormFormula) => {
    const next = [...formulas]
    next[index] = formula
    onChange(next)
  }

  const removeFormula = (index: number) => {
    if (formulas.length === 1)
      return
    const next = [...formulas]
    next.splice(index, 1)
    onChange(next)
  }

  const formulaErrors = errors.rules ? errors.rules[indexRule]?.formulas : undefined

  return (
    <div className={mergeClasses(s.column, s.gapS)}>
      {formulas.map((formula, index) => {
        const error = formulaErrors ? formulaErrors[index] : undefined
        return (
          <div className={error === undefined ? mergeClasses(s.formula, s.alignEnd) : mergeClasses(s.formula, s.alignCenter)} key={`${formula.id}`}>
            <div className={mergeClasses(s.row)}>
              <Field
                label={t('predict.params.number-head-from')}
                validation={error?.from ? error.from.message : undefined}
                className={s.field}
              >
                <Input
                  name={`rules.${indexRule}.formulas.${index}.from`}
                  value={formula.from}
                  onChange={(_e, data) => {
                    updateFormulas(index, { ...formula, from: data.value })
                  }}
                  appearance="filled-darker"
                  className={s.input}
                  type="number"
                  min={0}
                  required
                />
              </Field>
              <Field
                label={t('predict.params.number-head-to')}
                validation={error?.to ? error.to.message : undefined}
                className={s.field}
              >
                <Input
                  name={`rules.${indexRule}.formulas.${index}.to`}
                  value={formula.to}
                  onChange={(_e, data) => {
                    updateFormulas(index, { ...formula, to: data.value })
                  }}
                  appearance="filled-darker"
                  className={s.input}
                  type="number"
                  min={0}
                  required
                />
              </Field>

              <Field
                label={t('predict.params.diameter')}
                validation={error?.diameter ? error.diameter.message : undefined}
                className={s.field}
              >
                <Input
                  name={`rules.${indexRule}.formulas.${index}.diameter`}
                  value={formula.diameter}
                  onChange={(_e, data) => {
                    updateFormulas(index, { ...formula, diameter: data.value })
                  }}
                  appearance="filled-darker"
                  className={s.input}
                  type="number"
                  min={0}
                  required
                />
              </Field>
            </div>
            <Button
              appearance="subtle"
              icon={<Dismiss16Regular />}
              size="small"
              onClick={() => {
                removeFormula(index)
              }}
            />
          </div>
        )
      },
      )}
    </div>
  )
}
