import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import { downloadImage } from '../../debug/image'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import type { PredictFetchCrop } from '../fetch/crop'
import type { PredictFetch } from '../fetch/type'
import { parsePredictBoxes } from '../parse/box'
import { crop, getPredictFireHeads } from './fire-head'
import type { PredictModelBase } from './type'

export function isValidCropSamples(samples: PredictFetchCrop[], areas: PredictFetchCrop[]): boolean {
  const maxCropSample = samples.reduce((max, sample) => {
    const { w, h } = sample.source
    return Math.max(max, w, h)
  }, 0)

  const minCropArea = areas.reduce((min, area) => {
    const { w, h } = area.source
    return Math.min(min, w, h)
  }, Number.POSITIVE_INFINITY)

  return maxCropSample <= minCropArea
}

const fetch: PredictFetch = async (input) => {
  const normalized = await server.aiPredictNormalize({
    max: input.samples.reduce((max, sample) => {
      const { w, h } = sample.source
      return Math.max(max, w, h)
    }, 0),
    pageID: input.page,
    scale: input.area.source.scale,
  })

  const img = new Image()
  img.src = `data:image/png;base64,${normalized.payload}`

  await new Promise((resolve) => {
    img.onload = resolve
  })

  const samples = input.samples.map((sample) => {
    return crop({ img, box: sample.source })
  })
  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const templateImages = await Promise.all(samples)

  const image = await crop({ img, box: input.area.source })

  if (window.__debug__.download_images) {
    templateImages.forEach(sample => downloadImage(sample))
    downloadImage(image)
  }

  const heads = getPredictFireHeads(input)

  const raw = await server.predictFireProtectionAlarmHeadsByAi({
    image,
    pageID: input.page,
    sessionID: input.session,
    templateGroupIDs: input.samples.map(s => s.group!),
    detectedAlarmHeads: JSON.stringify(heads),
    templateImages,
    cropping: JSON.stringify(cropArea),
  })

  const boxes = parsePredictBoxes({
    boxes: raw.boxes,
    transform: input.area.globalise,
    fallbackEquip: ATTR_EQUIP_VALUES.LIGHTING_FIXTURES,
    aiPredictionRemaining: raw.aiPredictionRemaining,
  })
  return boxes
}

export const PredictModelElectrical = {
  value: 'electrical',
  label: t('predict.model.electrical'),
  system: 'electrical',
  sample: true,
  fetch,
  cleanUp: () => { },
  segment: null,
  additionalShape: false,
  aiParams: false,
} as const satisfies PredictModelBase
