import type { EquipmentAttributeTree, InsulationThickness } from '../../generated/server'
import { findTreeNode } from '../util/web/tree'

export type InsulEnabledValue = boolean

export const INSUL_ENABLED_DEFAULT: InsulEnabledValue = false

export type InsulTreeValue = string

export const INSUL_TREE_VALUE: InsulTreeValue = ''

export type InsulThicknessValue = string

export const INSUL_THICKNESS_DEFAULT: InsulThicknessValue = ''

export type InsulAttrValue = {
  enabled: InsulEnabledValue
  material1: InsulTreeValue
  material2: InsulTreeValue
  thickness: InsulThicknessValue
}

/**
 * Unlike the main annotation attribute, insulation attribute is expected to
 * be optional, so the default value is a full attribute that we will use
 * in place of these expected optional cases.
 *
 * In other words, when a shape is created, either via manual tool or ask AI,
 * we don't need to provide a corresponding insulation attribute value yet,
 * which we fall back to this default. This is also why it has a "freeze" below
 * to ensure it's only read but not updated.
 */
export const INSUL_ATTR_DEFAULT: InsulAttrValue = {
  enabled: INSUL_ENABLED_DEFAULT,
  material1: INSUL_TREE_VALUE,
  material2: INSUL_TREE_VALUE,
  thickness: INSUL_THICKNESS_DEFAULT,
} as const

Object.freeze(INSUL_ATTR_DEFAULT)

export function getInsulThickness(props: {
  insulTree: EquipmentAttributeTree
  equipValue: number
  insulMaterial2: string
}): string {
  const { insulTree, equipValue, insulMaterial2 } = props

  const server = findTreeNode(insulTree.tree, Number.parseInt(insulMaterial2))?.shapeOptions ?? null

  if (server === null)
    return ''

  const thickness = (server.data as InsulationThickness[])
    .find(option => option.equipmentClass === equipValue)?.thickness.toString() ?? ''

  return thickness
}
