import { t } from '../../util/intl/t'

export type PredictSystemBase = {
  value: string
  label: string
}

export const PredictSystemMechanical = {
  label: t('predict.system.mechanical'),
  value: 'mechanical',
} as const satisfies PredictSystemBase

export const PredictSystemFire = {
  label: t('predict.system.fire'),
  value: 'fire',
} as const satisfies PredictSystemBase

export const PredictSystemPlumbing = {
  label: t('predict.system.plumbing'),
  value: 'plumbing',
} as const satisfies PredictSystemBase

export const PredictSystemElectrical = {
  label: t('predict.system.electrical'),
  value: 'electrical',
} as const satisfies PredictSystemBase

export const PREDICT_SYSTEMS = [
  PredictSystemMechanical,
  PredictSystemFire,
  PredictSystemPlumbing,
  PredictSystemElectrical,
] as const satisfies PredictSystemBase[]

export type PredictSystem = typeof PREDICT_SYSTEMS[number]

export type PredictSystemValue = PredictSystem['value']
