import { Text, makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import type { defaultColorNames } from 'tldraw'
import { useEditorTheme } from '../../editor/util/theme'
import { EDITOR_ZOOM_VARIABLE } from '../../editor/util/zoom'
import { useSetting } from '../../setting/setting'
import type { PredictGroupSampleShape } from './shape'
import { GROUP_TEMPLATES } from './value'

// https://github.com/frenic/csstype?tab=readme-ov-file#what-should-i-do-when-i-get-type-errors
declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--psc-fill'?: string
  }
}

const useStyles = makeStyles({
  container: {
    '& svg path[fill]:not([fill=\'none\'])': {
      opacity: 0.5,
      // Use "solid" instead of the default "semi" colour.
      // See "area/component" for more details.
      fill: 'var(--psc-fill)',
    },
    '& svg path[stroke]:not([stroke=\'none\'])': {
      opacity: 0,
    },
  },
  label: {
    lineHeight: '0.75',
    fontWeight: 'bold',
    opacity: 1,
  },
})

export function PredictGroupSampleComponent(props: {
  original: ReactNode
  shape: PredictGroupSampleShape
}): ReactElement | null {
  const { original, shape } = props

  const s = useStyles()
  const theme = useEditorTheme()
  const dark = useSetting().setting.theme === 'dark'

  const selectedGroup = GROUP_TEMPLATES.find(group => group.value === shape.meta.group_template)

  const label = selectedGroup?.label ?? '-'
  const color: typeof defaultColorNames[number] = selectedGroup?.color ?? 'black'

  return (
    <div
      className={s.container}
      style={{ '--psc-fill': theme[color].solid }}
    >
      <div
        style={{
          backgroundColor: theme[color].solid,
          width: `calc(24px / var(${EDITOR_ZOOM_VARIABLE}))`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: 'translate(-50%, -50%)',
          borderRadius: '50%',
          height: `calc(24px / var(${EDITOR_ZOOM_VARIABLE}))`,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Text
          className={s.label}
          block
          style={{
            fontSize: `calc(14px / var(${EDITOR_ZOOM_VARIABLE}))`,
            color: dark ? 'black' : 'white',
          }}
        >
          {label}
        </Text>
      </div>
      {original}
    </div>
  )
}
