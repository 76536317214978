import { createContext, useContext } from 'react'
import type { EquipmentClass } from '../../../generated/server'
import type { SetState } from '../../../src/util/react/state'
import { ATTR_TREE_DEFAULT, type AttrTreeValue } from '../../util/attr/tree/value'
import { ATTR_C_MODULE_DEFAULT, type AttrCModuleValue } from '../field/c-module/value'
import type { AttrFirePipeValue } from '../field/fire-pipe/value'
import { ATTR_FIRE_PIPE_DEFAULT } from '../field/fire-pipe/value'
import { ATTR_SHAPE_DEFAULT, type AttrShapeValue } from '../field/shape/value'
import { ATTR_TYPE_DEFAULT, type AttrTypeValue } from '../field/type/value'

// @TODO: In practice, an attribute fields type can be used for both segment
// and piece shapes. However, this is unexpected and could lead to very
// difficult situations. There should be a meta field here to indicate whether
// this is for a segment or a piece, and the field must never change.
//
// Important: All values must be primitives (e.g., not objects or arrays) as we
// often shallow clone and compare them.
export type AttrValue = {
  cArea: AttrTreeValue
  cModule: AttrCModuleValue
  equip: EquipmentClass
  material1: AttrTreeValue
  material2: AttrTreeValue
  shape: AttrShapeValue
  type: AttrTypeValue
  firePipe: AttrFirePipeValue
}

export const ATTR_FIELDS_DEFAULT = {
  cArea: ATTR_TREE_DEFAULT,
  cModule: ATTR_C_MODULE_DEFAULT,
  material1: ATTR_TREE_DEFAULT,
  material2: ATTR_TREE_DEFAULT,
  shape: ATTR_SHAPE_DEFAULT,
  type: ATTR_TYPE_DEFAULT,
  firePipe: ATTR_FIRE_PIPE_DEFAULT,
  // @TODO: Why this is "Partial" instead of "Omit"?
} satisfies Partial<AttrValue>

export type AttrRecord = Record<
  string,
  AttrValue | undefined
>

export type AttrContext = {
  attrs: AttrRecord
  setAttrs: SetState<AttrRecord>
}

export const attrContext = createContext<AttrContext>({
  attrs: {},
  setAttrs: () => { throw new Error('AttrProvider is missing') },
})

export function useAttrs(): AttrContext {
  return useContext(attrContext)
}
