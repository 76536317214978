import { makeStyles, mergeClasses } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { Fragment, useEffect, useRef } from 'react'
import { Box, track, useEditor } from 'tldraw'
import { PAGE_PDF_SCALE, PAGE_PDF_SCALES } from '../../page/provider/pdf'
import { usePage } from '../../page/state/context'
import { useSetting } from '../../setting/setting'
import { getStrict } from '../../util/web/primitive'

const useStyles = makeStyles({
  canvas: {
    // Important: "canvas" must not take up space,
    // because tldraw uses absolute positioning for its shapes.
    position: 'absolute',
    top: 0,
    left: 0,
    visibility: 'hidden',
  },
  dark: {
    filter: 'invert(1)',
  },
})

/**
 * This component is actually "something" on tldraw's editor canvas,
 * which coincidentally is also a canvas.
 *
 * This canvas will host the PDF page drawing.
 * Since there's only one of it, it's more reliable (with a lot less code)
 * to define it here statically, and use "query select" it (instead of useRef).
 */
export const EditorOnTheCanvas = track((): ReactElement => {
  const s = useStyles()
  const dark = useSetting().setting.theme === 'dark'
  const { pdf } = usePage()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const editor = useEditor()

  const level = editor.getZoomLevel()
  const scaleIdx = PAGE_PDF_SCALES.findLastIndex(({ zoomLevel }) => level >= zoomLevel)

  // Zoom the editor to the PDF size
  useEffect(() => {
    const bound = new Box(0, 0, pdf.width, pdf.height)
    editor.zoomToBounds(bound, undefined)
  }, [pdf, editor])

  // Copy the PDF content over
  useEffect(() => {
    const context = getStrict(canvasRef.current?.getContext('2d'))
    const width = pdf.width * PAGE_PDF_SCALE
    const height = pdf.height * PAGE_PDF_SCALE
    context.clearRect(0, 0, width, height)
    context.drawImage(pdf.canvas, 0, 0, width, height)
  }, [pdf])

  return (
    <>
      {pdf.images.map(tile => (
        <Fragment key={tile.id}>
          {tile.sources.map((src, idx) => (
            <img
              key={`${tile.id}-${idx}`}
              src={src}
              className={dark ? mergeClasses('tl-shape', s.dark) : 'tl-shape'}
              style={{
                visibility: idx === scaleIdx ? 'visible' : 'hidden',
                position: 'absolute',
                pointerEvents: 'none',
                top: tile.shape.y,
                left: tile.shape.x,
                width: tile.shape.w,
                height: tile.shape.h,
              }}
            />
          ))}
        </Fragment>
      ))}
      <canvas
        ref={canvasRef}
        className={mergeClasses(s.canvas, dark ? s.dark : '')}
        // Original size
        style={{
          width: pdf.width,
          height: pdf.height,
        }}
        // Max scaled size
        width={pdf.width * PAGE_PDF_SCALE}
        height={pdf.height * PAGE_PDF_SCALE}
        draggable={false}
      />
    </>
  )
})
