import type { TLGeoShape } from 'tldraw'
import { Box } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { AnnotGeoTool } from '../../shape/geo'
import type { AnnotShape } from '../../shape/shape'
import { createElectricalPieceBoxAttr, createPieceBoxShape } from './create'
import { isElectricalPieceShape } from './shape'

export const ELECTRICAL_PIECE_TOOL_ID = 'electrical-piece-box'

const ELECTRICAL_PIECE_SIZE_WIDTH_DEFAULT = 8
const ELECTRICAL_PIECE_SIZE_HEIGHT_DEFAULT = 6

function getPrevGeo(props: {
  geo: TLGeoShape
  prev: AnnotShape | null
}): Box {
  const { geo, prev } = props

  const geoBox = new Box(geo.x, geo.y, geo.props.w, geo.props.h)
  const center = geoBox.center

  let [w, h] = [ELECTRICAL_PIECE_SIZE_WIDTH_DEFAULT, ELECTRICAL_PIECE_SIZE_HEIGHT_DEFAULT] // Default size when there's no previous shape
  if (prev) {
    // See the comment at "isPrev" below
    if (!isElectricalPieceShape(prev))
      throw new Error('Prev shape must be piece');
    [w, h] = [prev.props.w, prev.props.h]
  }

  return Box.FromCenter(center, { x: w, y: h })
}

export function createElectricalPieceTool(props: {
  setAttrs: SetState<AttrRecord>
}) {
  const { setAttrs } = props

  return class ElectricalPieceTool extends AnnotGeoTool {
    static override id = ELECTRICAL_PIECE_TOOL_ID

    override followTool = null

    // @TODO: This should enforce prev's type?
    override isPrev(shape: AnnotShape) {
      return isElectricalPieceShape(shape)
    }

    override createAttr(group: string): void {
      setAttrs(attrs => ({
        ...attrs,
        [group]: createElectricalPieceBoxAttr(),
      }))
    }

    override toAnnot: GeoToAnnot = (props) => {
      const { geo, prev } = props

      const box = createPieceBoxShape({
        box: getPrevGeo({ geo, prev }),
        id: geo.id,
        color: prev?.props.color ?? null,
        group: prev?.meta.group ?? null,
        interactive: 'ByManual',
        system: 'electrical',
      })
      return box
    }
  }
}
