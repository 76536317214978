import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { HelpCheckOpacity } from '../../help/actor/check-opacity.tsx'
import { HelpPointScale } from '../../help/actor/point-scale.tsx'
import { HelpCanvas } from '../../help/card/canvas.tsx'
import { HelpCard } from '../../help/card/card.tsx'
import { HelpText } from '../../help/card/text.tsx'
import { t } from '../../util/intl/t.ts'
import { FIRE_PIECE_TOOL_ID } from './box/fire-piece-tool.ts'
import { MECHANICAL_PIECE_TOOL_ID } from './check/tool.ts'

export const PieceHelp = track((): ReactElement | null => {
  const editor = useEditor()

  if (editor.getCurrentToolId() !== MECHANICAL_PIECE_TOOL_ID && editor.getCurrentToolId() !== FIRE_PIECE_TOOL_ID)
    return null

  return (
    <HelpCard>
      <HelpText>
        {t('annot.piece.help-1')}
      </HelpText>
      <HelpText>
        {t('annot.piece.help-2')}
      </HelpText>
      <HelpCanvas>
        <HelpCheckOpacity />
        <HelpPointScale />
      </HelpCanvas>
      <HelpText>
        {t('annot.piece.help-3')}
      </HelpText>
      <HelpText>
        {t('annot.piece.help-4')}
      </HelpText>
    </HelpCard>
  )
})
