import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { isPredictGroupSampleShape } from '../../../../predict/group-sample/shape'
import { PredictGroupSampleTool } from '../../../../predict/group-sample/tool'
import { t } from '../../../../util/intl/t'
import { HelpCanvas } from '../../../card/canvas'
import { HelpCard } from '../../../card/card'
import { HelpText } from '../../../card/text'
import { HelpFireHeadStatic } from './head-static'
import { HelpFireHeadScale } from './scale'
import { HelpFireHeadTranslate } from './translate'

export const FireSampleHeadHelp = track((): ReactElement | null => {
  const editor = useEditor()

  const shouldShow = false
    || editor.getCurrentToolId() === PredictGroupSampleTool.id
    || editor.getCurrentPageShapes().some(isPredictGroupSampleShape)

  if (!shouldShow)
    return null

  return (
    <HelpCard>
      <HelpText>
        {t('predict.sample-help.1')}
      </HelpText>
      <HelpText>
        {t('predict.sample-help.2')}
      </HelpText>
      <HelpText>
        {t('predict.sample-help.3')}
      </HelpText>
      <HelpCanvas>
        <HelpFireHeadStatic />
        <HelpFireHeadScale />
        <HelpFireHeadTranslate />
      </HelpCanvas>
      <HelpText>
        {t('predict.sample-help.4')}
      </HelpText>
      <HelpText>
        {t('predict.sample-help.5')}
      </HelpText>
    </HelpCard>
  )
})
