import type { defaultColorNames } from 'tldraw'

type Color = typeof defaultColorNames[number]

type Template = {
  label: string
  value: string
  color: Color
}

export const GROUP_TEMPLATES: Template[] = [
  {
    label: '1',
    value: crypto.randomUUID(),
    color: 'blue',
  },
  {
    label: '2',
    value: crypto.randomUUID(),
    color: 'green',
  },
  {
    label: '3',
    value: crypto.randomUUID(),
    color: 'orange',
  },
  {
    label: '4',
    value: crypto.randomUUID(),
    color: 'violet',
  },
  {
    label: '5',
    value: crypto.randomUUID(),
    color: 'yellow',
  },
  {
    label: '6',
    value: crypto.randomUUID(),
    color: 'light-blue',
  },
  {
    label: '7',
    value: crypto.randomUUID(),
    color: 'light-green',
  },
  {
    label: '8',
    value: crypto.randomUUID(),
    color: 'light-violet',
  },
  {
    label: '9',
    value: crypto.randomUUID(),
    color: 'light-red',
  },
  {
    label: '10',
    value: crypto.randomUUID(),
    color: 'blue',
  },
  {
    label: '11',
    value: crypto.randomUUID(),
    color: 'green',
  },
  {
    label: '12',
    value: crypto.randomUUID(),
    color: 'orange',
  },
  {
    label: '13',
    value: crypto.randomUUID(),
    color: 'violet',
  },
  {
    label: '14',
    value: crypto.randomUUID(),
    color: 'yellow',
  },
  {
    label: '15',
    value: crypto.randomUUID(),
    color: 'light-blue',
  },
  {
    label: '16',
    value: crypto.randomUUID(),
    color: 'light-green',
  },
  {
    label: '17',
    value: crypto.randomUUID(),
    color: 'light-violet',
  },
  {
    label: '18',
    value: crypto.randomUUID(),
    color: 'light-red',
  },
  {
    label: '19',
    value: crypto.randomUUID(),
    color: 'blue',
  },
  {
    label: '20',
    value: crypto.randomUUID(),
    color: 'green',
  },
]
