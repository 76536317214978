import { useQuery } from '@tanstack/react-query'
import { usePage } from '../../page/state/context'
import type { AIModelParamsDetail, ConstructionAIModelParamsDetail, OrgAIModelParamsDetail } from '../../util/data/server'
import { server } from '../../util/data/server'

export function useConstructionAIModelRules(): ConstructionAIModelParamsDetail[] {
  const { constructionID } = usePage().page
  const query = useQuery({
    queryKey: ['ai-model-rules', 'construction', constructionID],
    queryFn: () => server.listConstructionAiModelParams(constructionID),
  })
  return query.data ?? []
}

export function useOrgAIModelRules(): OrgAIModelParamsDetail[] {
  const { orgID } = usePage().page
  const query = useQuery({
    queryKey: ['ai-model-rules', 'org', orgID],
    queryFn: () => server.listOrgAiModelParams(orgID),
  })
  return query.data ?? []
}

export function useGlobalAIModelRules(): AIModelParamsDetail[] {
  const query = useQuery({
    queryKey: ['ai-model-rules', 'global'],
    queryFn: () => server.listAiModelParams(),
  })
  return query.data ?? []
}

export function useGetGlobalAIModelParams(id: number) {
  const query = useQuery({
    queryKey: ['ai-model-params', id],
    queryFn: () => server.getAiModelParams(id),
  })
  return query.data
}

export function useListContructionAIModelParams(constructionID: string) {
  const query = useQuery({
    queryKey: ['ai-model-constructions', constructionID],
    queryFn: () => server.listConstructionAiModelParams(constructionID),
  })
  return query
}
