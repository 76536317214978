import { Input, Select, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Toolbox16Filled } from '@fluentui/react-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { track, useEditor } from 'tldraw'
import { z } from 'zod'
import type { AIModelParamsDetail } from '../../../generated/server'
import { useAttrEquipOptions } from '../../attr/field/equip/value'
import { Field } from '../../ui/field'
import { locale } from '../../util/intl/locale/type'
import { t } from '../../util/intl/t'
import type { PredictAreaShape } from '../area/shape'
import { isPredictAreaShape } from '../area/shape'
import { PredictParamRuleForm } from './rule'
import type { AIParams } from './type'
import { parseAIModelParamsServer } from './type'

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    margin: `${tokens.spacingHorizontalM} 0 0 0`,
  },

  gapM: {
    rowGap: tokens.spacingHorizontalM,
  },
  select: {
    '> select': {
      minWidth: 'auto',
      paddingRight: 0,
    },
  },
  marginBottom: {
    marginBottom: `${tokens.spacingHorizontalM}`,
  },
})

const formulaSchema = z.object({
  id: z.string(),
  from: z.string().min(1, { message: t('predict.params.formula-required-field') }),
  to: z.string().min(1, { message: t('predict.params.formula-required-field') }),
  diameter: z.string().min(1, { message: t('predict.params.formula-required-field') }),
})

const schema = z.object({
  hydrantRule: z.object({
    equipmentClass: z.string(),
    exchangeValue: z.string().min(1, t('predict.params.formula-required-field')),
  }),
  rules: z.array(
    z.object({
      id: z.string(),
      equipmentClass: z.string(),
      formulas: z.array(
        formulaSchema.required(),
      ).superRefine((formulas, ctx) => {
        for (let i = 0; i < formulas.length; i++) {
          const from = formulas[i].from
          const to = formulas[i].to
          if (Number.parseInt(from) > Number.parseInt(to)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('predict.params.formula-invalid'),
              path: [`${i}.from`],
            })
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('predict.params.formula-invalid'),
              path: [`${i}.to`],
            })
          }

          for (let j = i + 1; j < formulas.length; j++) {
            const a = formulas[i]
            const b = formulas[j]
            // Check if the ranges overlap
            if (Number.parseInt(a.from) <= Number.parseInt(b.to) && Number.parseInt(b.from) <= Number.parseInt(a.to)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t('predict.params.formula-invalid-overlap'),
                path: [`${j}.from`],
              })
            }
          }
        }
      }),
    }),
  ),
})

export const PredictParamForm = track((props: {
  defaultValues: AIModelParamsDetail
  area: PredictAreaShape
}): ReactElement | null => {
  const { defaultValues, area } = props

  const s = useStyles()
  const editor = useEditor()

  const values = parseAIModelParamsServer(defaultValues)

  const formValues = area && isPredictAreaShape(area) && area.meta.params ? area.meta.params : values

  const form = useForm<AIParams>({
    defaultValues: formValues,
    resolver: zodResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
  })

  const { errors } = form.formState

  const language = locale === 'vi' ? 'ja' : locale

  const equipmentClasses = useAttrEquipOptions().filter(equip => equip.locale === language)

  const { getValues, setValue, trigger } = form

  const updateValues = (aiParams: AIParams): void => {
    editor.updateShape({
      ...area,
      meta: {
        ...area.meta,
        params: {
          ...aiParams,
        },
      },
    })
    editor.mark()
  }
  const params = form.getValues()

  useEffect(() => {
    if (area.meta.params === null) {
      editor.updateShape({
        ...area,
        meta: { ...area.meta, params: { ...formValues, state: 'valid' } },
      })
    }
    else {
      trigger()
    }
  }, [area, editor, formValues, trigger])

  useEffect(() => {
    const id = window.setTimeout(() => {
      if (Object.keys(errors).length > 0) {
        editor.updateShape({
          ...area,
          meta: { ...area.meta, params: { ...area.meta.params, state: 'error' } },
        })
      }
      else {
        editor.updateShape({
          ...area,
          meta: { ...area.meta, params: { ...area.meta.params, state: 'valid' } },
        })
      }
    }, 500)
    return () => {
      window.clearTimeout(id)
    }
  }, [errors, area, editor])

  return (
    <div>
      <div className={mergeClasses(s.gapM, s.column, s.marginBottom)}>
        <Field
          label={t('predict.params.hydrant-equip')}
          icon={icon => <Toolbox16Filled className={icon.className} />}
        >
          <Select
            name="hydrantRule.equipmentClass"
            appearance="filled-darker"
            value={getValues('hydrantRule.equipmentClass') ?? ''}
            onChange={(_e, data) => {
              setValue('hydrantRule.equipmentClass', data.value, { shouldValidate: true })
              updateValues({
                ...params,
                hydrantRule: {
                  ...params.hydrantRule,
                  equipmentClass: data.value,
                },
              })
            }}
            className={s.select}
          >
            {equipmentClasses.map((equip, _index) => (
              <option key={`${equip.equipmentClass}-locale`} value={equip.equipmentClass.toString()}>
                {equip.localed}
              </option>
            ))}
          </Select>
        </Field>
        <Field
          label={t('predict.params.exchange-value')}
          validation={errors.hydrantRule?.exchangeValue ? errors.hydrantRule?.exchangeValue?.message : undefined}
        >
          <Input
            name="hydrantRule.exchangeValue"
            defaultValue={getValues('hydrantRule.exchangeValue') ?? ''}
            onChange={(_e, data) => {
              setValue('hydrantRule', {
                ...form.watch('hydrantRule'),
                exchangeValue: data.value,
              }, { shouldValidate: true })
              updateValues({
                ...params,
                hydrantRule: {
                  ...params.hydrantRule,
                  exchangeValue: data.value,
                },
              })
            }}
            appearance="filled-darker"
            type="number"
          />
        </Field>
        <PredictParamRuleForm form={form} equipmentClasses={equipmentClasses} updateAreaShape={updateValues} />
      </div>
    </div>
  )
})
