import type { OptionOnSelectData } from '@fluentui/react-components'
import { Dropdown, Option } from '@fluentui/react-components'
import type { ComponentProps, ReactElement } from 'react'
import type { AttrValue } from '../../../attr/state/context'
import type { InsulAttrValue } from '../../../insul/value'
import { Field } from '../../../ui/field'
import type { AttrTreeDropdownValue } from './dropdown'
import type { AttrTreeValue } from './value'

type Key = keyof InsulAttrValue | keyof AttrValue

type Props = {
  name: string
  value: AttrTreeDropdownValue
  setValue: (value: Partial<AttrValue>) => void
  label: AttrTreeValue
  icon: ComponentProps<typeof Field>['icon']
  resetChildValue?: Key[]
}

/**
 * AttrTreeField may return "null" because AttrTreeValue is an ID (number) that
 * only makes sense when come from a list of options.
 *
 * This is different than AttrShapeField where AttrShapeValue is a text value
 * itself (string), so the options are only suggestions.
 */
export function AttrTreeField(props: Props): ReactElement | null {
  const { value, setValue, icon, label, resetChildValue, name } = props

  const onOptionSelect = (_event: unknown, data: OptionOnSelectData): void => {
    const next: Record<string, string> = {}

    if (resetChildValue)
      resetChildValue.forEach((child) => { next[child] = '' })

    if (data.optionValue === undefined)
      setValue({ [name]: '', ...next })

    else
      setValue({ [name]: data.optionValue ?? '', ...next })
  }

  return (
    <Field icon={icon} label={label}>
      {control => (
        <Dropdown
          value={value.display}
          selectedOptions={value.selectedOptions}
          onOptionSelect={onOptionSelect}
          className={control.className}
          appearance={control.appearance}
          clearable
        >
          {value.options.map(option => (
            <Option
              key={option.value}
              value={option.value.toString()}
            >
              {option.label}
            </Option>
          ))}
        </Dropdown>
      )}
    </Field>
  )
}
