import type { InsulationOption } from '../../data/server'
import { findTreeNode } from '../../web/tree'
import type { AttrSelection } from '../selection'
import { getAttrSelectionValueWhen } from '../selection'
import type { AttrTreeProps } from './data'
import { useAttrTreeSingle } from './data'
import type { AttrTreeOption, AttrTreeValue } from './value'

export type AttrTreeDropdownProps = {
  tree: AttrTreeProps & {
    /**
     * Heads up: The "root" node (where "id" is 0) needs a different handling
     * than other nodes. See the "children" value below for detail.
     */
    node: number
  }
  value: AttrSelection<AttrTreeValue>
  mixedText: string
}

export type AttrTreeDropdownValue = {
  /**
   * All options of the current field.
   */
  options: AttrTreeOption[]
  /**
   * The display value of the field.
   * This is often the human-friendly string representation of the selected value.
   */
  display: string
  /**
   * The insulation options of the selected value.
   * This is not really needed in most cases,
   * but it's used for automatic insulation calculation.
   */
  insulOptions: InsulationOption[] | null
  /**
   * The selected value of the field.
   * This is in the array format of the dropdown component for convenience.
   * In practice, it's always a single value.
   */
  selectedOptions: string[]
}

function useOptions(props: AttrTreeDropdownProps['tree']): AttrTreeOption[] | null {
  const { equip, node: nodeID, type } = props

  const tree = useAttrTreeSingle({ equip, type })
  if (tree === null)
    return null

  const node = findTreeNode(tree.tree, nodeID)
  if (node === null)
    return null

  const children = node.id === 0
    // Root node has options as its direct children
    ? node.children
    // Other nodes have "fields" as their direct children, so we need to go one
    // more level. This also means we don't support multiple fields inside an
    // option yet, as it does not have a practical usage so far.
    : node.children.at(0)?.children ?? null

  if (children === null)
    return null

  const options: AttrTreeOption[] = children.map(node => ({
    label: node.value,
    value: node.id.toString(),
    insulOptions: node.insulationOptions ?? null,
  }))

  return options
}

export function useAttrTreeDropdownValue(
  props: AttrTreeDropdownProps,
): null | AttrTreeDropdownValue {
  const { tree, value, mixedText } = props

  const options = useOptions(tree)

  // If there's not a tree, we should not render a dropdown.
  // This is different from a tree exists but has no options.
  if (options === null)
    return null

  // Missing "display" is expected because the options may not be loaded yet.
  // In all cases so far, it's best to fallback to "",
  // so we don't make it an option (like "mixed text") for now.
  const display = getAttrSelectionValueWhen(value, {
    mixed: mixedText,
    same: value => options.find(o => o.value === value)?.label,
  }) ?? ''

  // There are 3 cases where "insul options" is not available:
  // 1. The options are not loaded yet. This is similar to "display" above.
  //    It's an expected case.
  // 2. The node is available but it has no "insulation options" field.
  //    This is also an expected case,
  //    as the field is only available for some construction areas.
  // 3. The "value" is wrong, and there's not a corresponding option.
  //    This is an unexpected case in theory, but still expected in practical,
  //    because we may already removed the option from the tree.
  const insulOptions = getAttrSelectionValueWhen(value, {
    mixed: undefined,
    same: value => options.find(o => o.value === value)?.insulOptions,
  }) ?? null

  const selectedOptions = getAttrSelectionValueWhen<
    string,
    string[],
    string[]
  >(value, {
    mixed: [],
    same: value => value === '' ? [] : [value],
  })

  return { options, display, insulOptions, selectedOptions }
}
