import { DefaultColorStyle, shapeIdValidator } from 'tldraw'
import { ATTR_EQUIP_ELECTRICAL, ATTR_EQUIP_FIRE_PIECES, ATTR_EQUIP_VALUES, getEquipSystem } from '../../attr/field/equip/value'
import type { AnnotationData, Piece } from '../../util/data/server'
import { createPieceBoxShape } from './box/create'
import { createPieceCheckShape } from './check/create'
import { createPieceCircleShape } from './circle/create'
import type { PieceShape, PieceShapePartial } from './shape'

export function toServerPiece(client: PieceShape): Piece {
  const { x, y } = client
  const { w, h } = client.props

  const server: Piece = {
    uuid: client.id,
    point: { x, y },
    // Important: "point" is the single source of truth for x and y.
    // We should only use metadata for width and height.
    metadata: { bbox: { x: 0, y: 0, w, h } },
    interactive: client.meta.interactive !== null
      ? client.meta.interactive
      : undefined,
  }

  return server
}

export function fromServerPiece(props: {
  server: Piece
  group: AnnotationData
  equip: number
}): PieceShapePartial {
  const { server, group, equip } = props

  const { x, y } = server.point
  // Default size when there's no metadata
  // @TODO: This must be the same with the manual tool?
  const w = server.metadata?.bbox?.w ?? 10
  const h = server.metadata?.bbox?.h ?? 10

  if (ATTR_EQUIP_FIRE_PIECES.includes(equip) || ATTR_EQUIP_ELECTRICAL.includes(equip)) {
    const box = createPieceBoxShape({
      box: { x, y, w, h },
      id: shapeIdValidator.validate(server.uuid),
      color: DefaultColorStyle.validate(group.colorName),
      group: group.uuid,
      interactive: server.interactive ?? null,
      system: getEquipSystem(equip),
    })

    return box
  }

  else if (equip === ATTR_EQUIP_VALUES.WATER_SOURCE) {
    const circle = createPieceCircleShape({
      box: { x, y, w, h },
      id: shapeIdValidator.validate(server.uuid),
      color: DefaultColorStyle.validate(group.colorName),
      group: group.uuid,
      interactive: server.interactive ?? null,
    })

    return circle
  }

  const piece = createPieceCheckShape({
    box: { x, y, w, h },
    id: shapeIdValidator.validate(server.uuid),
    color: DefaultColorStyle.validate(group.colorName),
    group: group.uuid,
    interactive: server.interactive ?? null,
  })

  return piece
}
