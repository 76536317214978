import type { AnnotShape } from '../annot/shape/shape'
import { ATTR_THICKNESS_DEFAULT } from '../attr/field/thickness/value'
import { ATTR_TREE_DEFAULT } from '../util/attr/tree/value'
import type { Insulation } from '../util/data/server'
import { getHeadStrict } from '../util/web/array'
import type { InsulAttrValue } from './value'

export function toInsulServer(props: {
  client: InsulAttrValue
  shapes: AnnotShape[]
}): Insulation | null {
  const { client, shapes } = props

  if (client.enabled === false)
    return null

  const head = getHeadStrict(shapes)

  const interactive = shapes.every(s => s.meta.interactive === 'ByAI')
    ? 'ByAI'
    : shapes.every(s => s.meta.interactive === 'ByManual')
      ? 'ByManual'
      : 'ByBoth'

  const material = []
  if (client.material1)
    material.push(Number.parseInt(client.material1))
  if (client.material2)
    material.push(Number.parseInt(client.material2))

  return {
    uuid: crypto.randomUUID(),
    // Derived value from parent
    colorName: head.props.color,
    // Self values
    material,
    thickness: Number(client.thickness),
    // Not supported anytime soon
    area: 0,
    attributes: undefined,
    constructionArea: undefined,
    length: 0, // Will calculate in FE
    materialExtra: undefined,
    shape: undefined,
    interactive,
  }
}

export function fromInsulServer(server: Insulation | null): InsulAttrValue {
  return {
    enabled: server !== null,
    material1: server?.material?.at(0)?.toString() ?? ATTR_TREE_DEFAULT,
    material2: server?.material?.at(1)?.toString() ?? ATTR_TREE_DEFAULT,
    thickness: server?.thickness.toFixed(0) ?? ATTR_THICKNESS_DEFAULT,
  }
}
