import { Box } from 'tldraw'
import { createElectricalPieceBoxAttr, createFirePieceBoxAttr, createPieceBoxShape } from '../../annot/piece/box/create'
import { randomAnnotShapeColor } from '../../annot/shape/color'
import { type AttrEquipValue, getEquipSystem, isElectricalPieceEquip, isFirePieceEquip } from '../../attr/field/equip/value'
import type { AIOutputTemplateSearchOutput } from '../../util/data/server'
import { groupBy } from '../../util/web/object'
import type { PredictFetchCropTransform } from '../fetch/crop'
import type { PredictFetchOutput } from '../fetch/type'
import { parsePredictEquip } from './equip'
import { parsePredictShape } from './shape'
import { parsePredictType } from './type'

export function parsePredictBoxes(props: {
  boxes: AIOutputTemplateSearchOutput[]
  transform: PredictFetchCropTransform
  fallbackEquip: AttrEquipValue
  aiPredictionRemaining: number
}): PredictFetchOutput {
  const { boxes, transform, fallbackEquip, aiPredictionRemaining } = props

  const output: PredictFetchOutput = { attrs: {}, shapes: [], aiPredictionRemaining }

  // AI's box are not grouped, but we can and should manually group them by
  // group_id
  const boxGroups = groupBy({
    array: boxes,
    getKey: box => box.group_id ?? '',
  })

  Object
    .values(boxGroups)
    .forEach((boxGroup) => {
      // Important: create a new group and color for the whole box group,
      // not for each individual box.
      const group = crypto.randomUUID()
      const color = randomAnnotShapeColor()

      if (isElectricalPieceEquip(fallbackEquip)) {
        output.attrs[group] = {
          ...(createElectricalPieceBoxAttr()),
          shape: parsePredictShape(boxGroup),
          type: parsePredictType({ boxes: boxGroup }),
          equip: parsePredictEquip(boxGroup) ?? fallbackEquip,
        }
      }

      if (isFirePieceEquip(fallbackEquip)) {
        output.attrs[group] = {
          ...(createFirePieceBoxAttr()),
          shape: parsePredictShape(boxGroup),
          type: parsePredictType({ boxes: boxGroup }),
          equip: parsePredictEquip(boxGroup) ?? fallbackEquip,
        }
      }

      boxGroup.forEach((aiBox) => {
        const { height, width, x, y } = aiBox.box
        const local = new Box(x, y, width, height)
        const global = transform.box(local)
        const piece = createPieceBoxShape({
          box: global,
          color,
          group,
          id: null,
          interactive: 'ByAI',
          system: getEquipSystem(fallbackEquip),
        })
        output.shapes.push(piece)
      })
    })

  return output
}
