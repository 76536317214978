import { isSegmentFlatShape } from '../../annot/segment/flat/shape'
import { getLineShapeEdgeAbsolute } from '../../editor/shape/line'
import type { AIOutputFireProtectionSegment } from '../../util/data/server'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import type { PredictFetch, PredictFetchInput, PredictFetchOutput } from '../fetch/type'
import { toAIModelParamsServer } from '../param/type'
import { getPredictFireHeads } from './fire-head'
import { getWaterSources, parsePredictFirePipes } from './fire-pipe'
import type { PredictModelBase } from './type'

// type AIOutputFireProtectionSegmentCustom = Omit<AIOutputFireProtectionSegment, 'type'>

function getSegments(input: PredictFetchInput): AIOutputFireProtectionSegment[] {
  return input.shapes
    .filter(isSegmentFlatShape)
    .map((shape): AIOutputFireProtectionSegment => {
      const { start, end } = getLineShapeEdgeAbsolute(shape)

      const points = [start, end]
      const local = input.area.localise.line([points[0], points[1]])
      const [p1, p2] = local

      const attr = input.attrs[shape.meta.group]
      if (attr === undefined)
        throw new Error(`attr is missing for ${shape.meta.group}`)

      return {
        type: attr.firePipe,
        diameter: -1,
        p1: { x: p1.x, y: p1.y },
        p2: { x: p2.x, y: p2.y },
      }
    })
}

const fetch: PredictFetch = async (input) => {
  const output: PredictFetchOutput = {
    attrs: {},
    shapes: [],
    aiPredictionRemaining: Number.POSITIVE_INFINITY,
  }

  const heads = getPredictFireHeads(input)
  const segments = getSegments(input)
  const waterSources = getWaterSources(input)

  // @TODO Should we throw error or say something?
  if (heads.length === 0 || segments.length === 0)
    return output

  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const params = toAIModelParamsServer(input.aiParams)
  const raw = await server.predictFireProtectionPipeDiametersByAi({
    pageID: input.page,
    sessionID: input.session,
    image: input.area.blob,
    sprinklerHeads: JSON.stringify(heads),
    segments: JSON.stringify(segments),
    waterSources: JSON.stringify(waterSources),
    cropping: JSON.stringify(cropArea),
    params: params ? JSON.stringify(params) : undefined,
  })

  return parsePredictFirePipes({ input, raw })
}

/**
 * Fire pipe diameter returns new fire pipes along with the diameters,
 * so we need to delete the existing fire pipes to avoid duplicates.
 */
const cleanUp: PredictModelBase['cleanUp'] = async (input) => {
  const { editor, shapes } = input
  editor.deleteShapes(shapes.filter(isSegmentFlatShape))
}

export const PredictModelFirePipeDiameter = {
  value: 'fire-pipe-diameter',
  label: t('predict.model.fire-pipe-diameter'),
  system: 'fire',
  sample: false,
  fetch,
  cleanUp,
  segment: 'shape',
  additionalShape: true,
  aiParams: true,
} as const satisfies PredictModelBase
