import type { TLGeoShape } from 'tldraw'
import { Box } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { AnnotGeoTool } from '../../shape/geo'
import type { AnnotShape } from '../../shape/shape'
import { createFirePieceBoxAttr, createPieceBoxShape } from './create'
import { isFirePieceShape } from './shape'

export const FIRE_PIECE_TOOL_ID = 'fire-piece-box'

const FIRE_PIECE_SIZE_DEFAULT = 3

function getPrevGeo(props: {
  geo: TLGeoShape
  prev: AnnotShape | null
}): Box {
  const { geo, prev } = props

  const geoBox = new Box(geo.x, geo.y, geo.props.w, geo.props.h)
  const center = geoBox.center

  let [w, h] = [FIRE_PIECE_SIZE_DEFAULT, FIRE_PIECE_SIZE_DEFAULT] // Default size when there's no previous shape
  if (prev) {
    // See the comment at "isPrev" below
    if (!isFirePieceShape(prev))
      throw new Error('Prev shape must be piece');
    [w, h] = [prev.props.w, prev.props.h]
  }

  return Box.FromCenter(center, { x: w, y: h })
}

export function createFirePieceTool(props: {
  setAttrs: SetState<AttrRecord>
}) {
  const { setAttrs } = props

  return class FirePieceTool extends AnnotGeoTool {
    static override id = FIRE_PIECE_TOOL_ID

    override followTool = null

    // @TODO: This should enforce prev's type?
    override isPrev(shape: AnnotShape) {
      return isFirePieceShape(shape)
    }

    override createAttr(group: string): void {
      setAttrs(attrs => ({
        ...attrs,
        [group]: createFirePieceBoxAttr(),
      }))
    }

    override toAnnot: GeoToAnnot = (props) => {
      const { geo, prev } = props

      const box = createPieceBoxShape({
        box: getPrevGeo({ geo, prev }),
        id: geo.id,
        color: prev?.props.color ?? null,
        group: prev?.meta.group ?? null,
        interactive: 'ByManual',
        system: 'fire',
      })
      return box
    }
  }
}
